var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('Branch'))+" "),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.branchData.add_branches == 2},on:{"click":function($event){_vm.isAddNewBranch = true}}},[_c('v-icon',{staticClass:"me-1"},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" "+_vm._s(_vm.$t('add_branch'))+" ")],1)],1),_c('v-row',{staticClass:"px-2 mb-2"},[_c('v-col',{attrs:{"cols":"12","md":"6","lg":"4"}},[_c('v-text-field',{attrs:{"dense":"","outlined":"","hide-details":"","label":_vm.$t('search')},model:{value:(_vm.searchtext),callback:function ($$v) {_vm.searchtext=$$v},expression:"searchtext"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.columns,"items":_vm.dataTableList,"options":_vm.options,"loading":_vm.loading,"disable-sort":"","hide-default-footer":"","loading-text":_vm.$t('data_loading'),"no-data-text":_vm.$t('no_information')},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.shop_id_pri",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s(index + 1)+" ")]}},{key:"item.shop_image",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{staticClass:"my-1"},[_c('v-img',{attrs:{"src":item.shop_image}})],1)]}},{key:"item.user_fullname",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user_fullname)+" ("+_vm._s(item.username)+") ")]}},{key:"item.shop_status_id",fn:function(ref){
var item = ref.item;
return [_c('StatusBlock',{attrs:{"status":item.shop_status_id}})]}},{key:"item.shop_create",fn:function(ref){
var item = ref.item;
return [_c('ConverseDate',{attrs:{"date":item.shop_create}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.branchId = item.shop_id_pri; _vm.isShowDetail = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiInformationOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("detail")))])]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","disabled":item.shop_status_id == 2},on:{"click":function($event){_vm.dataEdit = item;_vm.isEditBranch = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPencilOutline))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("edit")))])]),_c('v-tooltip',{attrs:{"color":"#212121","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","disabled":item.shop_status_id == 2},on:{"click":function($event){_vm.branchId = item.shop_id_pri; _vm.statusUpdate =item.shop_status_id; _vm.isUpdateStatus = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(item.shop_status_id==1?_vm.icons.mdiDeleteOutline:_vm.icons.mdiCheck))])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.shop_status_id==1? _vm.$t("suspend"):_vm.$t('normal')))])])]}}],null,true)})],1),_c('AddNewBranch',{on:{"onAdd":_vm.fetchDataTable},model:{value:(_vm.isAddNewBranch),callback:function ($$v) {_vm.isAddNewBranch=$$v},expression:"isAddNewBranch"}}),_c('EditBranch',{attrs:{"data-edit":_vm.dataEdit},on:{"onUpdate":_vm.fetchDataTable},model:{value:(_vm.isEditBranch),callback:function ($$v) {_vm.isEditBranch=$$v},expression:"isEditBranch"}}),_c('DetailBranch',{attrs:{"id":_vm.branchId},model:{value:(_vm.isShowDetail),callback:function ($$v) {_vm.isShowDetail=$$v},expression:"isShowDetail"}}),_c('v-dialog',{attrs:{"persistent":"","max-width":"350"},model:{value:(_vm.isUpdateStatus),callback:function ($$v) {_vm.isUpdateStatus=$$v},expression:"isUpdateStatus"}},[_c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-icon',{staticClass:"me-1",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.icons.mdiAlertOutline)+" ")]),_vm._v(" "+_vm._s(_vm.statusUpdate ==1?_vm.$t('confirmSuspendBranch'):_vm.$t('cancelSuspendBranch'))+" "),_c('v-spacer')],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","loading":_vm.loadingUpdate,"disabled":_vm.loadingUpdate},on:{"click":_vm.updateStatus}},[_vm._v(" "+_vm._s(_vm.$t('confirm'))+" ")]),_c('v-btn',{attrs:{"color":"secondary","outlined":""},on:{"click":function($event){_vm.isUpdateStatus = false}}},[_vm._v(" "+_vm._s(_vm.$t('cancel'))+" ")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }