<template>
  <v-dialog
    v-model="isAddNewBranch"
    persistent
    max-width="800"
  >
    <v-card>
      <v-card-title>
        {{ $t('add_branch') }}
      </v-card-title>
      <v-form
        ref="formAddNewBrach"
        @submit.prevent="addBranch"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_name"
                dense
                hide-details="auto"
                :rules="[required]"
                outlined
                :label="$t('shop_name')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_nature"
                :rules="[required]"
                dense
                hide-details="auto"
                outlined
                :label="$t('nature_service')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_license"
                dense
                :rules="[required]"
                outlined
                hide-details="auto"
                :label="$t('license_number')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_address"
                dense
                outlined
                :rules="[required]"
                hide-details="auto"
                :label="$t('address')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_tel"
                dense
                :rules="[required]"
                hide-details="auto"
                outlined
                :label="$t('tel')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_district"
                dense
                hide-details="auto"
                :rules="[required]"
                outlined
                :label="$t('sub_district')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_email"
                dense
                :rules="[required]"
                hide-details="auto"
                outlined
                :label="$t('email')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_amphoe"
                dense
                :rules="[required]"
                outlined
                hide-details="auto"
                :label="$t('district')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_fax"
                dense
                hide-details="auto"
                outlined
                :label="$t('fax')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_province"
                dense
                hide-details="auto"
                outlined
                :rules="[required]"
                :label="$t('province')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_tax"
                dense
                hide-details="auto"
                outlined
                :label="$t('tax_number')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_zipcode"
                dense
                :rules="[required]"
                hide-details="auto"
                outlined
                :label="$t('zip_code')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_promptpay_id"
                dense
                hide-details="auto"
                outlined
                :label="$t('PromptpayId')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="shop_promptpay_name"
                dense
                hide-details="auto"
                outlined
                :label="$t('PromptpayName')"
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="user_fullname"
                dense
                hide-details="auto"

                :rules="[required]"
                outlined
                :label="$t('main_user')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="user_tel"
                dense
                hide-details="auto"
                :rules="[required]"
                outlined
                :label="$t('tel')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="username"
                dense
                hide-details="auto"
                outlined
                label="Username"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="password"
                dense
                :rules="[required]"
                hide-details="auto"
                outlined
                type="password"
                label="Password"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="user_email"
                dense
                outlined
                hide-details="auto"
                :rules="[required]"
                :label="$t('email')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-add-new-branch', false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref } from '@vue/composition-api'
import branch from '@/api/branch'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isAddNewBranch',
    event: 'update:is-add-new-branch',
  },
  props: {
    isAddNewBranch: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const shop_name = ref('')
    const shop_license = ref('')
    const shop_nature = ref('')
    const shop_tax = ref('')
    const shop_tel = ref('')
    const shop_fax = ref('')
    const shop_email = ref('')
    const shop_address = ref('')
    const shop_district = ref('')
    const shop_amphoe = ref('')
    const shop_province = ref('')
    const shop_zipcode = ref('')
    const shop_promptpay_id = ref('')
    const shop_promptpay_name = ref('')
    const username = ref('')
    const password = ref('')
    const user_fullname = ref('')
    const user_email = ref('')
    const user_tel = ref('')
    const loading = ref(false)

    const formAddNewBrach = ref(null)

    const { branchAdd } = branch

    const addBranch = () => {
      const isFormValid = formAddNewBrach.value.validate()
      if (!isFormValid) return
      loading.value = true
      branchAdd({
        shop_name: shop_name.value,
        shop_license: shop_license.value,
        shop_nature: shop_nature.value,
        shop_tax: shop_tax.value,
        shop_tel: shop_tel.value,
        shop_fax: shop_fax.value,
        shop_email: shop_email.value,
        shop_address: shop_address.value,
        shop_district: shop_district.value,
        shop_amphoe: shop_amphoe.value,
        shop_province: shop_province.value,
        shop_zipcode: shop_zipcode.value,
        shop_promptpay_id: shop_promptpay_id.value,
        shop_promptpay_name: shop_promptpay_name.value,
        username: username.value,
        password: password.value,
        user_fullname: user_fullname.value,
        user_email: user_email.value,
        user_tel: user_tel.value,
      }).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onAdd')
        emit('update:is-add-new-branch', false)
        loading.value = false
      })
    }

    return {
      shop_name,
      shop_license,
      shop_nature,
      shop_tax,
      shop_tel,
      shop_fax,
      shop_email,
      shop_address,
      shop_district,
      shop_amphoe,
      shop_province,
      shop_zipcode,
      shop_promptpay_id,
      shop_promptpay_name,
      required,
      username,
      password,
      user_fullname,
      user_email,
      user_tel,
      formAddNewBrach,
      loading,
      addBranch,
    }
  },

}
</script>
