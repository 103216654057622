<template>
  <v-dialog
    v-model="isEditBranch"
    persistent
    max-width="800"
  >
    <v-card>
      <v-card-title>
        {{ $t('edit_branch') }}
      </v-card-title>
      <v-form
        ref="formEditBrach"
        @submit.prevent="updateBranch"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_name"
                dense
                hide-details="auto"
                :rules="[required]"
                outlined
                :label="$t('shop_name')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_nature"
                :rules="[required]"
                dense
                hide-details="auto"
                outlined
                :label="$t('nature_service')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_license"
                dense
                :rules="[required]"
                outlined
                hide-details="auto"
                :label="$t('license_number')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_address"
                dense
                outlined
                :rules="[required]"
                hide-details="auto"
                :label="$t('address')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_tel"
                dense
                :rules="[required]"
                hide-details="auto"
                outlined
                :label="$t('tel')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_district"
                dense
                hide-details="auto"
                :rules="[required]"
                outlined
                :label="$t('sub_district')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_email"
                dense
                :rules="[required]"
                hide-details="auto"
                outlined
                :label="$t('email')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_amphoe"
                dense
                :rules="[required]"
                outlined
                hide-details="auto"
                :label="$t('district')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_fax"
                dense
                hide-details="auto"
                outlined
                :label="$t('fax')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_province"
                dense
                hide-details="auto"
                outlined
                :rules="[required]"
                :label="$t('province')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_tax"
                dense
                hide-details="auto"
                outlined
                :label="$t('tax_number')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_zipcode"
                dense
                :rules="[required]"
                hide-details="auto"
                outlined
                :label="$t('zip_code')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_promptpay_id"
                dense
                hide-details="auto"
                outlined
                :label="$t('PromptpayId')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.shop_promptpay_name"
                dense
                hide-details="auto"
                outlined
                :label="$t('PromptpayName')"
              ></v-text-field>
            </v-col>
            <v-divider></v-divider>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.user_fullname"
                dense
                hide-details="auto"

                :rules="[required]"
                outlined
                :label="$t('main_user')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.user_tel"
                dense
                hide-details="auto"
                :rules="[required]"
                outlined
                :label="$t('tel')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.username"
                dense
                hide-details="auto"
                outlined
                disabled
                label="Username"
                :rules="[required]"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.password"
                dense
                :rules="[required]"
                hide-details="auto"
                outlined
                type="password"
                label="Password"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model.trim="dataEditLocal.user_email"
                dense
                outlined
                hide-details="auto"
                :rules="[required]"
                :label="$t('email')"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            type="submit"
            :loading="loading"
            :disabled="loading"
          >
            {{ $t('save') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="$emit('update:is-edit-branch', false)"
          >
            {{ $t('cancel') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import branch from '@/api/branch'
import store from '@/store'
import { required } from '@/@core/utils/validation'

export default {
  model: {
    prop: 'isEditBranch',
    event: 'update:is-edit-branch',
  },
  props: {
    isEditBranch: {
      type: Boolean,
      default: false,
    },
    dataEdit: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const formEditBrach = ref(null)
    const dataEditLocal = ref({})

    const { branchUpdate } = branch

    const updateBranch = () => {
      const isFormValid = formEditBrach.value.validate()
      if (!isFormValid) return
      loading.value = true
      branchUpdate(dataEditLocal.value).then(res => {
        store.commit('app/ALERT', {
          message: res.message,
          color: res.response ? 'success' : 'error',
        })
        emit('onUpdate')
        emit('update:is-edit-branch', false)
        loading.value = false
      })
    }

    watch(() => props.isEditBranch, value => {
      if (value) {
        dataEditLocal.value = JSON.parse(JSON.stringify(props.dataEdit))
      }
    })

    return {
      dataEditLocal,
      formEditBrach,
      required,
      loading,
      updateBranch,
    }
  },

}
</script>
