<template>
  <v-dialog
    v-model="isShowDetail"
    persistent
    max-width="600"
  >
    <v-card>
      <v-card-title>
        {{ $t('detail') }} <v-spacer></v-spacer>
        <v-btn
          color="error"
          icon
          @click="$emit('update:is-show-detail', false)"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
            align="center"
          >
            <v-img
              :src="shopData.shop_image"
              width="150"
              class="mb-2"
            ></v-img>
            {{ $t('status') }} :  <StatusBlock :status="shopData.shop_status_id" />
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <p><span class="font-weight-bold">{{ $t('shop_name') }} : </span>  <span>{{ shopData.shop_name }}</span> </p>
            <p><span class="font-weight-bold">{{ $t('license_number') }} : </span> <span>{{ shopData.shop_license }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('tel') }} : </span> <span>{{ shopData.shop_tel }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('email') }} : </span> <span>{{ shopData.shop_email }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('fax') }} : </span> <span>{{ shopData.shop_fax }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('tax_number') }} : </span> <span>{{ shopData.shop_tax }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('PromptpayId') }} : </span> <span>{{ shopData.shop_promptpay_id }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('main_user') }} : </span> <span>{{ shopData.user_fullname }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('username') }} : </span> <span>{{ shopData.username }}</span> </p>
          </v-col>
          <v-divider vertical></v-divider>
          <v-col
            cols="12"
            md="6"
          >
            <p> <span class="font-weight-bold">{{ $t('nature_service') }} : </span> <span>{{ shopData.shop_nature }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('address') }} : </span> <span>{{ shopData.shop_address }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('sub_district') }} : </span> <span>{{ shopData.shop_district }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('district') }} : </span> <span>{{ shopData.shop_amphoe }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('province') }} : </span> <span>{{ shopData.shop_province }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('zip_code') }} : </span> <span>{{ shopData.shop_zipcode }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('PromptpayName') }} : </span> <span>{{ shopData.shop_promptpay_name }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('tel') }} : </span> <span>{{ shopData.user_tel }}</span> </p>
            <p> <span class="font-weight-bold">{{ $t('email') }} : </span> <span>{{ shopData.user_email }}</span> </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiClose } from '@mdi/js'
import branch from '@/api/branch'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'

export default {
  components: {
    StatusBlock,
  },
  model: {
    prop: 'isShowDetail',
    event: 'update:is-show-detail',
  },
  props: {
    isShowDetail: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const shopData = ref({})

    const { branchGet } = branch

    watch(() => props.isShowDetail, value => {
      if (value) {
        branchGet(props.id).then(res => {
          shopData.value = res
        })
      }
    })

    return {
      shopData,
      icons: {
        mdiClose,
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
