<template>
  <div>
    <v-card>
      <v-card-title>
        {{ $t('Branch') }} <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :disabled="branchData.add_branches == 2"
          @click="isAddNewBranch = true"
        >
          <v-icon class="me-1">
            {{ icons.mdiPlus }}
          </v-icon> {{ $t('add_branch') }}
        </v-btn>
      </v-card-title>
      <v-row class="px-2 mb-2">
        <v-col
          cols="12"
          md="6"
          lg="4"
        >
          <v-text-field
            v-model="searchtext"
            dense
            outlined
            hide-details
            :label="$t('search')"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-data-table
        :headers="columns"
        :items="dataTableList"
        :options.sync="options"
        :loading="loading"
        disable-sort
        hide-default-footer
        :loading-text="$t('data_loading')"
        :no-data-text="$t('no_information')"
      >
        <template v-slot:[`item.shop_id_pri`]="{ index }">
          {{ index + 1 }}
        </template>
        <template v-slot:[`item.shop_image`]="{ item }">
          <v-avatar class="my-1">
            <v-img :src="item.shop_image"></v-img>
          </v-avatar>
        </template>
        <template v-slot:[`item.user_fullname`]="{ item }">
          {{ item.user_fullname }} ({{ item.username }})
        </template>
        <template v-slot:[`item.shop_status_id`]="{ item }">
          <StatusBlock :status="item.shop_status_id" />
        </template>
        <template v-slot:[`item.shop_create`]="{ item }">
          <ConverseDate :date="item.shop_create" />
        </template>
        <template v-slot:[`item.actions`]="{ item }">
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="branchId = item.shop_id_pri; isShowDetail = true"
                v-on="on"
              >
                <v-icon>{{ icons.mdiInformationOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("detail") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="dataEdit = item;isEditBranch = true"
                v-on="on"
                :disabled= "item.shop_status_id == 2"
              >
                <v-icon>{{ icons.mdiPencilOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("edit") }}</span>
          </v-tooltip>
          <v-tooltip
            color="#212121"
            top
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="primary"
                v-bind="attrs"
                @click="branchId = item.shop_id_pri; statusUpdate =item.shop_status_id; isUpdateStatus = true "
                v-on="on"
                :disabled= "item.shop_status_id == 2"
              >
                <v-icon>{{ item.shop_status_id==1?icons.mdiDeleteOutline:icons.mdiCheck }}</v-icon>
              </v-btn>
            </template>
            <span>{{ item.shop_status_id==1? $t("suspend"):$t('normal') }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <AddNewBranch
      v-model="isAddNewBranch"
      @onAdd="fetchDataTable"
    />
    <EditBranch
      v-model="isEditBranch"
      :data-edit="dataEdit"
      @onUpdate="fetchDataTable"
    />
    <DetailBranch
      :id="branchId"
      v-model="isShowDetail"
    />
    <v-dialog
      v-model="isUpdateStatus"
      persistent
      max-width="350"
    >
      <v-card>
        <v-card-title>
          <v-spacer></v-spacer>
          <v-icon
            color="warning"
            class="me-1"
          >
            {{ icons.mdiAlertOutline }}
          </v-icon> {{ statusUpdate ==1?$t('confirmSuspendBranch'):$t('cancelSuspendBranch') }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            :loading="loadingUpdate"
            :disabled="loadingUpdate"
            @click="updateStatus"
          >
            {{ $t('confirm') }}
          </v-btn>
          <v-btn
            color="secondary"
            outlined
            @click="isUpdateStatus = false"
          >
            {{ $t('cancel') }}
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiPlus, mdiCheck, mdiDeleteOutline, mdiPencilOutline, mdiInformationOutline,
} from '@mdi/js'
import ConverseDate from '@/components/basicComponents/ConverseDate.vue'
import StatusBlock from '@/components/basicComponents/StatusBlock.vue'
import useBranchList from './useBranchList'
import AddNewBranch from './AddNewBranch.vue'
import EditBranch from './EditBranch.vue'
import DetailBranch from './DetailBranch.vue'

export default {
  components: {
    ConverseDate,
    StatusBlock,
    AddNewBranch,
    EditBranch,
    DetailBranch,
  },
  setup() {
    const {
      searchtext,
      columns,
      options,
      dataTableList,
      footer,
      branchData,
      loading,
      isAddNewBranch,
      isEditBranch,
      dataEdit,
      isUpdateStatus,
      statusUpdate,
      isShowDetail,
      loadingUpdate,
      branchId,
      fetchDataTable,
      updateStatus,
    } = useBranchList()

    return {
      searchtext,
      columns,
      options,
      dataTableList,
      isAddNewBranch,
      footer,
      branchData,
      loading,
      isUpdateStatus,
      statusUpdate,
      fetchDataTable,
      updateStatus,
      isEditBranch,
      loadingUpdate,
      dataEdit,
      isShowDetail,
      branchId,
      icons: {
        mdiPlus,
        mdiCheck,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiInformationOutline,
      },
    }
  },
}

</script>
