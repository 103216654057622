import { ref, watch } from '@vue/composition-api'
import branch from '@/api/branch'
import footerDataTables from '@/components/basicComponents/footerDataTables.json'
import footerDataTablesEN from '@/components/basicComponents/footerDataTablesEN.json'
import { i18n } from '@/plugins/i18n'
import store from '@/store'

export default function useBranchList() {
  const searchtext = ref('')
  const columns = ref([
    {
      text: '#',
      align: 'start',
      value: 'shop_id_pri',
      width: 30,
    },
    {
      text: i18n.t('logo'),
      width: 80,
      value: 'shop_image',
      align: 'center',
    },
    {
      text: i18n.t('branch_code'),
      width: 100,
      value: 'shop_id',
    },
    {
      text: i18n.t('shop_name'),
      width: 200,
      value: 'shop_name',
    },
    {
      text: `${i18n.t('main_user')} (Username)`,
      width: 200,
      value: 'user_fullname',
    },

    {
      text: i18n.t('tel'),
      value: 'user_tel',
      width: 100,
    },
    {
      text: i18n.t('status'),
      value: 'shop_status_id',
      width: 100,
    },
    {
      text: i18n.t('shop_creation_date'),
      value: 'shop_create',
      width: 150,
    },
    {
      text: i18n.t('option'),
      value: 'actions',
      sortable: false,
      width: 200,
      align: 'center',
    },
  ])
  const options = ref({})
  const dataTableList = ref([])
  const branchData = ref({})
  const loading = ref(false)
  const footer = ref(i18n.locale == 'en' ? footerDataTablesEN : footerDataTables)

  const isAddNewBranch = ref(false)
  const isEditBranch = ref(false)
  const dataEdit = ref({})
  const isShowDetail = ref(false)
  const branchId = ref('')
  const isUpdateStatus = ref(false)
  const loadingUpdate = ref(false)

  const statusUpdate = ref('')
  const { branchListGet, branchStatusUpdate } = branch
  const fetchDataTable = () => {
    loading.value = true
    branchListGet({
      searchtext: searchtext.value,
    }).then(res => {
      branchData.value = res
      dataTableList.value = res.data
      loading.value = false
    })
  }

  const updateStatus = () => {
    loadingUpdate.value = true
    branchStatusUpdate({
      shop_id_pri: branchId.value,
      shop_status_id: statusUpdate.value == 1 ? 2 : 1,
    }).then(res => {
      store.commit('app/ALERT', {
        message: res.message,
        color: res.response ? 'success' : 'error',
      })
      fetchDataTable()
      loadingUpdate.value = false
      isUpdateStatus.value = false
    })
  }

  watch([searchtext, options], (newvalue, oldvalue) => {
    if (newvalue[1] !== oldvalue[1]) {
      options.value.page = 1
    }
    fetchDataTable()
  })

  return {
    isAddNewBranch,
    searchtext,
    columns,
    options,
    dataTableList,
    footer,
    loading,
    branchData,
    isUpdateStatus,
    statusUpdate,
    isEditBranch,
    dataEdit,
    loadingUpdate,
    isShowDetail,
    branchId,
    fetchDataTable,
    updateStatus,

  }
}
